<template>
  <div
    :class="[
      'navbar-feed-icon',
      { 'navbar-feed-icon--variant-success': isSuccessVariant },
      { 'navbar-feed-icon--variant-danger': isDangerVariant },
    ]"
  >
    <Icon :name="iconName" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { includes } from 'lodash';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    variant: {
      type: String,
      default: 'success',
      validator: (value) => includes(['success', 'danger'], value),
    },
  },
  setup(props) {
    const isSuccessVariant = computed(() => props.variant === 'success');
    const isDangerVariant = computed(() => props.variant === 'danger');
    const iconName = computed(() => {
      if (isSuccessVariant.value) return 'check';
      if (isDangerVariant.value) return 'exclamation';
      return '';
    });

    return {
      isSuccessVariant,
      isDangerVariant,
      iconName,
    };
  },
};
</script>

<style lang="scss">
.navbar-feed-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  .icon {
    width: 100%;
    height: 100%;
    padding: 2px;
  }

  &--variant-success {
    background: #00BC57;

    .icon {
      stroke: #fff;
    }
  }

  &--variant-danger {
    background: #FF2E2D;
  }
}
</style>
