<template>
  <transition name="fade">
    <div
      v-if="notifications.length"
      class="notifications"
    >
      <div
        :class="[
          'notification-wrapper',
          {'success': notification.type === 'success'},
          {'error': notification.type === 'error'},
        ]"
        v-for="notification in notifications"
        :key="notification.id"
      >
        <div class="message-box">
          <div class="message-text">
            {{ notification.message }}
          </div>
          <div
            class="message-close"
            @click="closeNotification(notification)"
          >
            <icon :name="'x'" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();
    const notifications = computed(() => store.getters.notifications);
    const closeNotification = (notification) => {
      store.dispatch('removeNotification', notification.id);
    };

    return {
      notifications,
      closeNotification,
    };
  },
};
</script>

<style lang="scss">
.notifications {
  position: fixed;
  right: 80px;
  bottom: 54px;
  width: 100%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  z-index: 99999;

  .notification-wrapper {
    width: 100%;
    min-height: 44px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    border-radius: 0px 6px 6px 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
    border-left: 3px solid;
    padding: 1rem;
    box-sizing: border-box;

    &.success {
      border-color: $success500;
    }

    &.error {
      border-color: $error500;

      .icon-box .icon {
        border-color: $error500;

        svg {
          stroke: $error500;
        }
      }
    }

    .icon-box {
      min-width: 62px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      .icon {
        border-radius: 50%;
        border: 2px solid;
        border-color: $success500;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;

        svg {
          height: 24px;
          width: 24px;
          stroke: $success500;
          path {
            stroke-width: 1px;
          }
        }
      }
    }

    .message-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;
      width: 100%;

      .message-type {
        height: 17px;
        line-height: 17px;
        font-weight: 600;
        color: #000;
        margin-bottom: 8px;
      }

      .message-text {
        line-height: 17px;
        color: #6D6D6D;
        max-width: 90%;
      }

      .message-close {
        height: 14px;
        width: 14px;
        cursor: pointer;

        .icon {
          width: 100%;
          height: 100%;

          svg {
            stroke: #6D6D6D;
            path {
              stroke-width: 4px;
            }
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
