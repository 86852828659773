<template>
  <div
    :class="[
      'radio-input',
      { 'radio-input--checked': checked }
    ]"
  />
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.radio-input {
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 2px solid #DDDDDD;
  border-radius: 100%;
  overflow: hidden;

  &::after {
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5rem;
    height: 0.5rem;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    transition: all linear 100ms;
    content: '';
  }
}

.radio-input--checked {
  border-color: #003C3C;

  &::after {
    background-color: #003C3C;
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
