<template>
  <i
    v-if="componentName"
    class="icon"
  >
    <component :is="componentName" />
  </i>
</template>

<script>
import {
  reduce, replace, kebabCase, endsWith,
} from 'lodash';
import { computed } from 'vue';
import * as svgComponents from '@/components/svg';
import sportIds from '@/services/helpers/sports';

const {
  SOCCER_ID,
  BASKETBALL_ID,
  FOOTBALL_ID,
  BASEBALL_ID,
  ULTIMATE_ID,
  RUGBY_LEAGUE_ID,
  RUGBY_UNION_ID,
  TENNIS_ID,
  VOLLEYBALL_ID,
  HOCKEY_ID,
} = sportIds;

const iconsBySportId = {
  [FOOTBALL_ID]: svgComponents.FootballIcon,
  [BASEBALL_ID]: svgComponents.BaseballIcon,
  [BASKETBALL_ID]: svgComponents.BasketballIcon,
  [RUGBY_LEAGUE_ID]: svgComponents.FootballIcon,
  [RUGBY_UNION_ID]: svgComponents.FootballIcon,
  [SOCCER_ID]: svgComponents.SoccerIcon,
  [TENNIS_ID]: svgComponents.TennisIcon,
  [VOLLEYBALL_ID]: svgComponents.VolleyballIcon,
  [ULTIMATE_ID]: svgComponents.UltimateIcon,
  [HOCKEY_ID]: svgComponents.HockeyIcon,
};

const components = reduce(
  svgComponents,
  (iconLibrary, componentValue, componentName) => {
    if (!endsWith(componentName, 'Icon')) return iconLibrary;
    const iconName = replace(kebabCase(componentName), '-icon', '');
    return { ...iconLibrary, [iconName]: componentValue };
  },
  iconsBySportId,
);

export default {
  components,
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const componentName = computed(() => components[props.name]);

    return {
      componentName,
    };
  },
};
</script>

<style lang="scss">
.icon {
  width: $iconSize;
  height: $iconSize;
}
</style>
