<template>
  <div
    :class="{
      spinner: true,
      'spinner-small': small,
    }"
  >
    <svg
      class="spinner-icon"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="spinner-icon-path"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spinnerPadding;
  height: 100%;

  .spinner-icon {
    width: $spinnerIconSize;
    height: $spinnerIconSize;
    animation: $spinnerIconAnimation;
  }

  .spinner-icon-path {
    stroke: $spinnerIconPathStroke;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transform-origin: center;
    animation: $spinnerIconPathAnimation;
  }

  &.spinner-small {
    padding: $spinnerSmallPadding;

    .spinner-icon {
      width: $spinnerSmallIconSize;
      height: $spinnerSmallIconSize;
    }
  }
}
</style>
