<template>
  <ul
    ref="rootElement"
    :class="[
      'dropdown-menu',
      { 'dropdown-menu--right': right },
      { 'dropdown-menu--top': top }
    ]"
    :style="style"
  >
    <slot />
  </ul>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

const FOOTER_HEIGHT_IN_PX = 60;

export default {
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    menuTopValue: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const rootElement = ref(null);
    const top = ref(false);

    const style = computed(() => {
      if (top.value) return {};
      return { top: props.menuTopValue ? `-${props.menuTopValue}px` : '100%' };
    });

    onMounted(() => {
      const box = rootElement.value.getBoundingClientRect();
      const boxBottom = box.top + box.height + FOOTER_HEIGHT_IN_PX;
      top.value = boxBottom >= window.innerHeight;
    });

    return {
      rootElement,
      top,
      style,
    };
  },
};
</script>

<style lang="scss">
.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  margin-top: 4px;
  background-color: $white;
  color: $black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 1);
  z-index: $dropdownMenuZIndex;

  &.dropdown-menu--top {
    top: unset;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 4px;
  }

  &.dropdown-menu--right {
    left: unset;
    right: 0;
  }
}
</style>
