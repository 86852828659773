<template>
  <component :is="layout" />
</template>

<script>
import { computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import * as api from '@/services/api';
import DefaultLayout from '@/layouts/DefaultLayout';
import PublicLayout from '@/layouts/PublicLayout';

const layouts = {
  default: DefaultLayout,
  public: PublicLayout,
};

export default {
  components: layouts,
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const layout = computed(() => {
      const layoutName = route.meta?.layout || 'default';
      return layouts[layoutName] || layouts.default;
    });

    onMounted(async () => {
      try {
        // I. Authenticate current user
        const {
          token,
          userRoles,
          operator,
        } = await api.isUserLoggedIn();
        store.dispatch('setUserToken', token);
        store.dispatch('updateUserRoles', userRoles);
        store.dispatch('updateOperator', operator);
        store.dispatch('connectWebSocket');
        store.dispatch('initializeSelectedOddsFormat');
      } catch (error) {
        console.error(error);
        await store.dispatch('logout');
        router.push({ name: 'login' });
      }
    });

    return {
      layout,
    };
  },
};
</script>
