<template>
  <div
    class="navbar"
    :class="{ 'is-super-admin': showSuperAdminBadge }"
  >
    <div class="navbar-brand">
      <div
        ref="applicationHubRoot"
        class="navbar-brand__dropdown"
      >
        <div
          class="navbar-brand__dropdown-toggle"
          @click="toggleApplicationHubsMenu"
        >
          <Icon
            name="dots-grid"
          />
        </div>
        <div
          v-if="isApplicationHubsMenuToggled"
          class="navbar-brand__dropdown-menu"
        >
          <div class="navbar-brand__dropdown-header">
            Application Hubs
          </div>
          <div
            v-for="hub in applicationHubs"
            :key="hub.id"
            class="navbar-brand__dropdown-item"
            @click.left="setApplicationHub(hub.id)"
            @click.middle="openApplicationHub(hub.id)"
          >
            {{ hub.label }}
            <Icon
              v-if="currentApplicationHub === hub.id"
              name="check"
            />
          </div>
        </div>
      </div>
      <component
        class="navbar-brand__logo"
        :is="environmentLogoComponent"
      />
    </div>

    <div
      v-if="!hasDpsTraderRole"
      class="navbar-content"
    >
      <div class="navbar-content__left">
        <div
          v-for="(link, index) in allowedLinks"
          :key="index"
          :class="['navbar-item ', link.key === selectedLink && 'active']"
          @click.left="navbarItemClicked(link)"
          @click.middle="navbarNewTabItemClicked(link)"
        >
          <div
            v-if="!link.items"
          >
            {{ link.label }}
          </div>

          <Dropdown
            v-else
            :label="link.label"
          >
            <DropdownItem
              v-for="(childLink, childIndex) in link.items"
              :key="childIndex"
              clickable
              @click="navbarItemClicked(link, childLink.to)"
              @click.middle="navbarNewTabItemClicked(link, childLink.to)"
            >
              {{ childLink.label }}
            </DropdownItem>
          </Dropdown>
        </div>
      </div>

      <div class="navbar-content__right">
        <div
          v-if="showOperatorSelect"
          class="navbar-item operator-select"
        >
          <Dropdown
            :label="selectedOperator"
          >
            <div class="client-group-title">
              Superadmin
            </div>
            <DropdownItem
              clickable
              @click="onOperatorChange(SUPER_ADMIN_CLIENT)"
            >
              <div class="dd-item-left-side">
                <Icon :name="getIconByOperator(SUPER_ADMIN_CLIENT)" />
                <p>{{ SUPER_ADMIN_CLIENT }}</p>
              </div>
              <div
                v-if="selectedOperator === SUPER_ADMIN_CLIENT"
                class="dd-item-right-side"
              >
                <Icon name="check" />
              </div>
            </DropdownItem>
            <div class="divider" />
            <div class="client-group-title">
              Clients
            </div>
            <DropdownItem
              v-for="operator in operatorsList"
              :key="operator.operatorId"
              clickable
              @click="onOperatorChange(operator.operatorId)"
            >
              <div class="dd-item-left-side">
                <Icon
                  v-if="getIconByOperator(operator.operatorId)"
                  :name="getIconByOperator(operator.operatorId)"
                />
                <p>{{ operator.operatorId }}</p>
              </div>
              <div
                v-if="selectedOperator === operator.operatorId"
                class="dd-item-right-side"
              >
                <Icon name="check" />
              </div>
            </DropdownItem>
          </Dropdown>
        </div>
        <div class="navbar-item">
          <NavbarFeed />
        </div>
        <div class="navbar-item user-menu">
          <Dropdown
            :label="username"
          >
            <DropdownItem
              clickable
              @mouseenter="onOddsFormatHover(true)"
              @mouseleave="onOddsFormatHover(false)"
              @click="oddsFormatListDisplayed = false"
            >
              <div class="user-menu-dropdown-label-group">
                <Icon :name="'hash'" />
                <p>Odds format</p>
              </div>
              <Icon :name="'chevron-right'" />
            </DropdownItem>
            <DropdownItem
              clickable
              @click="logout()"
            >
              <div class="user-menu-dropdown-label-group">
                <Icon :name="'logout'" />
                <p>Logout</p>
              </div>
            </DropdownItem>
          </Dropdown>
          <div
            v-show="oddsFormatListDisplayed"
            class="odds-format-dropdown__list"
            @mouseenter="onOddsFormatListHover(true)"
            @mouseleave="onOddsFormatListHover(false)"
          >
            <div
              v-for="option in oddsFormats"
              :key="option.id"
              class="odds-format-dropdown__list-item"
              @click="updateOddsSelection(option)"
            >
              <RadioInput
                :checked="option.id === selectedOddFormat.id"
              />
              {{ option.label }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul
      v-if="hasDpsTraderRole"
      class="navbar-content"
    >
      <div class="navbar-item">
        <Dropdown
          :label="username"
        >
          <DropdownItem
            clickable
            @click="logout()"
          >
            <Icon :name="'logout'" />
            <p>Logout</p>
          </DropdownItem>
        </Dropdown>
      </div>
    </ul>

    <div
      v-if="showSuperAdminBadge"
      class="super-admin-indicator"
    >
      Super admin
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { find, filter, startsWith } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import {
  EnvironmentDevelopmentLogo,
  EnvironmentStagingLogo,
  EnvironmentProductionLogo,
} from '@/components/svg';
import RadioInput from '@/components/common/RadioInput';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import Icon from './Icon';
import NavbarFeed from './NavbarFeed';
import { getSuperAdminData, getIconByOperator } from '@/services/helpers/super-admin';
import {
  getApplicationHubs,
  getOperationsLinks,
  getProductAdminLinks,
  getAllowedLinks,
} from '@/services/helpers/navbar';
import env from '@/../config/env';

export default {
  components: {
    EnvironmentDevelopmentLogo,
    EnvironmentStagingLogo,
    EnvironmentProductionLogo,
    Dropdown,
    DropdownItem,
    Icon,
    RadioInput,
    NavbarFeed,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const environmentLogoComponent = computed(() => {
      switch (env.serverEnv) {
      case 'production':
        return 'EnvironmentProductionLogo';
      case 'staging':
        return 'EnvironmentStagingLogo';
      default:
        return 'EnvironmentDevelopmentLogo';
      }
    });
    const applicationHubRoot = ref();
    const applicationHubs = getApplicationHubs();
    const currentApplicationHub = computed(() => {
      if (startsWith(route.path, '/operations')) return 'operations';
      if (startsWith(route.path, '/product-admin')) return 'product-admin';
      return '';
    });
    const isApplicationHubsMenuToggled = ref(false);
    const links = computed(() => {
      switch (currentApplicationHub.value) {
      case 'operations':
        return getOperationsLinks();
      case 'product-admin':
        return getProductAdminLinks();
      default:
        return [];
      }
    });
    const toggleApplicationHubsMenu = () => {
      isApplicationHubsMenuToggled.value = !isApplicationHubsMenuToggled.value;
    };
    const setApplicationHub = (newApplicationHub) => {
      router.push({ name: newApplicationHub });
      isApplicationHubsMenuToggled.value = false;
    };
    const openApplicationHub = (applicationHub) => {
      const url = router.resolve({ name: applicationHub }).href;
      window.open(url, '_blank');
      isApplicationHubsMenuToggled.value = false;
    };
    onClickOutside(applicationHubRoot, () => {
      isApplicationHubsMenuToggled.value = false;
    });

    const userRoles = computed(() => store.getters.userRoles);
    const hasDpsTraderRole = computed(() => store.getters.isDpsTraderRole);
    const { isSuperAdmin, SUPER_ADMIN_CLIENT } = getSuperAdminData();
    const operatorsList = computed(() => filter(store.getters.operatorsList, (operator) => operator.operatorId !== SUPER_ADMIN_CLIENT));
    const selectedOperator = computed(() => route.query.client);
    const showOperatorSelect = computed(() => isSuperAdmin && operatorsList.value?.length);
    const showSuperAdminBadge = computed(() => isSuperAdmin && selectedOperator.value === SUPER_ADMIN_CLIENT);
    const oddsFormatListDisplayed = ref(false);
    const oddsFormatListHovered = ref(false);

    const username = computed(() => localStorage.getItem('username'));
    const findSelectedRouteKey = (currentRouteName) => {
      const selectedRoute = find(links.value, (link) => {
        if (link.items) {
          return find(link.items, { to: { name: currentRouteName } });
        }
        return link.to.name === currentRouteName;
      });
      return selectedRoute?.key;
    };
    const selectedLink = ref(findSelectedRouteKey(router.currentRoute.value.name));
    const allowedLinks = computed(() => getAllowedLinks(userRoles.value, links.value));

    const navigate = (routeTo) => {
      router.push(routeTo);
    };

    const navbarItemClicked = (item, to = '') => {
      if (!item.to && !to) return;
      navigate(to || item.to);
    };

    const navbarNewTabItemClicked = (item, to = '') => {
      if (!item.to && !to) return;
      const pathName = to?.name || item.to?.name;
      const routeData = router.resolve({ name: pathName, query: {} });
      window.open(routeData.href, '_blank');
    };

    const oddsFormats = computed(() => store.getters.oddFormats);
    const selectedOddFormat = computed(() => store.getters.selectedOddFormat);

    const updateOddsSelection = (oddFormat) => {
      store.dispatch('updateSelectedOddsFormat', oddFormat);
      oddsFormatListDisplayed.value = false;
    };

    const logout = (event) => {
      if (event && event?.keyCode !== 13) return;
      store.dispatch('logout')
        .then(() => {
          navigate({ name: 'login' });
        });
    };

    const onOperatorChange = (operator) => {
      // Special case when we are on sport template page and change the operator
      // In this case we want to return user back to the market templates list page
      if (route.name === 'sport-template') {
        router.push({
          name: 'market-templates',
          query: {
            ...route.query,
            client: operator,
          },
        });
        return;
      }
      router.replace({
        ...route,
        query: {
          ...route.query,
          client: operator,
        },
      }).then(() => {
        // When the client is changed we want to refresh the page
        router.go(0);
      });
    };

    const onOddsFormatHover = (isHovered) => {
      if (!isHovered) {
        setTimeout(() => {
          oddsFormatListDisplayed.value = oddsFormatListHovered.value;
        }, 250);
      }
      oddsFormatListDisplayed.value = true;
    };

    const onOddsFormatListHover = (isHovered) => {
      oddsFormatListHovered.value = isHovered;
      if (!isHovered) oddsFormatListDisplayed.value = false;
    };

    onMounted(() => {
      if (isSuperAdmin) {
        store.dispatch('getAllOperators');
      }
    });

    watch(() => router.currentRoute.value.name, (newVal) => {
      if (!newVal) return;
      selectedLink.value = findSelectedRouteKey(newVal);
    });

    return {
      environmentLogoComponent,
      applicationHubRoot,
      applicationHubs,
      currentApplicationHub,
      isApplicationHubsMenuToggled,
      toggleApplicationHubsMenu,
      setApplicationHub,
      openApplicationHub,
      username,
      operatorsList,
      selectedOperator,
      showOperatorSelect,
      SUPER_ADMIN_CLIENT,
      showSuperAdminBadge,
      allowedLinks,
      hasDpsTraderRole,
      selectedLink,
      navigate,
      logout,
      navbarItemClicked,
      navbarNewTabItemClicked,
      onOperatorChange,
      getIconByOperator,
      oddsFormats,
      selectedOddFormat,
      oddsFormatListDisplayed,
      updateOddsSelection,
      onOddsFormatHover,
      onOddsFormatListHover,
    };
  },
};
</script>

<style lang="scss">
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: $navbarTextColor;
  height: $navbarHeight;
  min-height: $navbarHeight;
  padding: $navbarPadding;
  position: relative;
  z-index: $mainNavigationZIndex;
  border-bottom: 1px solid #F0F0F0;

  &.is-super-admin {
    border-bottom: 1px solid $brandPrimary500;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 4px;

    &__dropdown {
      position: relative;

      &-toggle {
        width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;

        .icon {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
        }
      }

      &-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 170px;
        margin-top: 4px;
        background-color: $white;
        color: $black;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid rgba(221, 221, 221, 1);
        z-index: $dropdownMenuZIndex;
        padding: 4px 0;
      }

      &-header {
        font-family: 'Rubik', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 11.85px;
        text-transform: uppercase;
        color: #6D6D6D;
        padding: 4px 8px;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        white-space: nowrap;
        min-width: 100%;
        height: 32px;
        padding: 0 8px;
        cursor: pointer;
        user-select: none;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #191414;
        stroke: #191414;

        &:hover,
        &:focus {
          background-color: #FAFAFA;
          outline: none;
        }
      }
    }

    &__logo {
      width: $navbarBrandWidth;
      height: $navbarBrandHeight;
    }
  }

  .navbar-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    position: relative;

    .navbar-content__left,
    .navbar-content__right {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .navbar-item {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin: $navbarItemMargin;
    padding: $navbarItemPadding;
    color: $gray800;
    cursor: pointer;
    position: relative;

    & .button {
      background-color: transparent;
      color: $gray800;
      border: none;

      &:focus {
        box-shadow: none;
      }
    }

    &.active::after,
    &:hover::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: 100px 100px 0px 0px;
    }

    &:hover::after { background-color: #99b2b1; }
    &.active::after { background-color: $brandPrimary; }

    &.operator-select {
      .client-group-title {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 4px 8px;
        color: $gray700;
      }
      .divider {
        height: 1px;
        background-color: $gray400;
      }
      .dropdown-menu {
        border: 1px solid $gray500;
        .dropdown-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: unset;
          height: auto;
          padding: 8px;
          gap: 4px;
          min-width: 150px;
          .dd-item-left-side {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .dd-item-right-side {
            .icon svg path {
              stroke: $black;
            }
          }
        }
      }
    }

    &.feed-status-wrapper {
      height: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;
      position: relative;

      .feed-status-label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        .status-label {
          height: 16px;
          line-height: 18px;
        }

      }

      .feeds-dropdown {
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: absolute;
        top: 55px;
        left: 0;
        width: 216px;
        z-index: $dropdownMenuZIndex;
        cursor: default;

        .feed-option {
          height: 44px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          color: #191414;
        }
      }

      .status-icon {
        height: 16px;
        width: 16px;
        background: #00BC57;
        border-radius: 8px;
        margin-left: 4px;
        border: 2px solid #017134;

        &.down {
          background: #FF2E2D;
          border: 2px solid #CC2523;
        }
      }
    }
    .dropdown-item {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    &.user-menu {
      .dropdown-menu {
        right: 0;
        left: unset;
        width: 150px;

        .dropdown-item {
          display: flex;
          justify-content: space-between;

          .user-menu-dropdown-label-group {
            display: flex;
            height: 100%;
            align-items: center;

            .icon {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }

        .icon svg path {
          stroke: #191414;
        }
      }
    }

    .odds-format-dropdown__list {
      background-color: #fff;
      position: absolute;
      top: 55px;
      width: 180px;
      right: 162px;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      overflow: hidden;
      padding: 4px 0;
    }

    .odds-format-dropdown__list-item {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 8px;
      gap: 0.5rem;
      cursor: pointer;
      color: #191414;

      &:hover {
        background-color: #FAFAFA;
      }
    }
  }
  .super-admin-indicator {
    display: inline-block;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 12px;
    border: 1px solid $brandPrimary500;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13px;
    color: $brandPrimary500;
    background-color: $white;
  }
}
</style>
